// assets
import { IconBrandChrome, IconHelp, IconSettings } from '@tabler/icons';

// constant
const icons = { IconHelp, IconSettings };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        // {
        //     id: 'sample-page',
        //     title: 'Settings',
        //     type: 'item',
        //     url: '/sample-page',
        //     icon: icons.IconSettings,
        //     breadcrumbs: false
        // },
        {
            id: 'documentation',
            title: 'Documentation',
            type: 'item',
            url: 'https://codedthemes.gitbook.io/berry/',
            icon: icons.IconHelp,
            external: true,
            target: true
        }
    ]
};

export default other;
