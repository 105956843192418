import { Button } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import ContainerBody from './component/helpers/ContainerBody.js';
import Hero from './component/hero/BackgroundAsImageWithCenteredContent.js';
import AnimationRevealPage from './component/helpers/AnimationRevealPage';
import Footer from './component/footers/MiniCenteredFooter.js';
import FAQ from './component/faqs/SingleCol';
import tw from 'twin.macro';
import Features from './component/features/ThreeColWithSideImage';
import DownloadApp from './component/cta/DownloadApp.js';

export default function LandingPage() {
    const faqRef = React.useRef(null);
    const path = window.location.pathname;

    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/dashboard');
    };

    function handleScrollTo(url) {
        console.log(url);
        faqRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    React.useLayoutEffect(() => {
        handleScrollTo(path);
    }, [path]);

    return (
        <AnimationRevealPage>
            <Hero />
            <DownloadApp
                text={
                    <>
                        People around you are enjoying great serving using the{' '}
                        <HighlightedTextInverse>Danxakidatahub App.</HighlightedTextInverse>
                    </>
                }
            />
            <Features
                subheading={<Subheading>Features</Subheading>}
                heading={
                    <>
                        We have Amazing <HighlightedText>Service.</HighlightedText>
                    </>
                }
            />
            <div ref={faqRef}>
                <FAQ
                    subheading={<Subheading>FAQS</Subheading>}
                    heading={
                        <>
                            You have <HighlightedText>Questions ?</HighlightedText>
                        </>
                    }
                    faqs={[
                        {
                            question: 'Are all the services list available ?',
                            answer: 'Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
                        },
                        {
                            question: 'How long do you usually support an standalone template for ?',
                            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
                        },
                        {
                            question: 'What kind of payment methods do you accept ?',
                            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
                        },
                        {
                            question: 'Is there a subscribption service to get the latest templates ?',
                            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
                        },
                        {
                            question: 'Are the templates compatible with the React ?',
                            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
                        },
                        {
                            question: 'Do you really support Internet Explorer 11 ?',
                            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
                        }
                    ]}
                />
            </div>
            <Footer />
        </AnimationRevealPage>
    );
}
