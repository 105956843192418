import { create } from 'apisauce';

export const api = create({
    baseURL: 'https://danxaki.farookcircle.com'
});

// export const api = create({
//     baseURL: 'http://ec2-34-232-67-244.compute-1.amazonaws.com'
// });

// dev

// export const api = create({
//     baseURL: 'http://localhost:5000'
// });
