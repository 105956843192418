import React from 'react';
import {
    Grid,
    Link,
    Button,
    Input,
    Box,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    Stack,
    Typography,
    Divider,
    TextField
} from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';
import { IconPhone, IconSearch, IconX } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import TransactionsCard from './component/TransacitonCard';
import { getTransactionsHistory } from 'api/api.transaction';
import ModalC from 'views/dashboard/components/Modal';

// ==============================|| TYPOGRAPHY ||============================== //

export const TextValue = ({ text, value }) => (
    <Stack>
        <Typography>{text}</Typography>
        <Typography fontWeight={'bold'}>{value}</Typography>
    </Stack>
);

const Transactions = () => {
    const [transactions, setTransactions] = React.useState([]);
    const [selected, setSelected] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);

    const toggleModal = () => setShowModal(!showModal);

    React.useEffect(() => {
        getTransactions();
    }, []);
    const getTransactions = async () => {
        const response = await getTransactionsHistory();
        if (response.ok) {
            setTransactions(response.data.data.reverse());
        }
    };
    return (
        <>
            <ModalC isOpen={showModal} setOpen={toggleModal}>
                <Stack sx={{ cursor: 'pointer' }} direction={'row'} justifyContent="space-between">
                    <Typography>Transaction</Typography>
                    <IconX onClick={toggleModal} />
                </Stack>
                {selected && (
                    <Stack alignItems={'center'}>
                        <Box p="15px" backgroundColor="rgb(39, 193,45)" borderRadius="30px" marginTop="20px">
                            <Typography>{selected.transactionType}</Typography>
                        </Box>
                        <Typography marginTop="10px" fontWeight="bold">
                            {selected.createdAt}
                        </Typography>
                        {selected.transactionType === 'ELECTRICITY_ORDER' && (
                            <>
                                <Typography marginTop="10px" fontWeight="bold">
                                    Meter Token
                                </Typography>
                                <Typography marginTop="2px" fontWeight="bold">
                                    {selected.details.meter.token || 'Pending'}
                                </Typography>
                            </>
                        )}
                        <Stack width="100%" marginTop="30px" spacing={'10px'}>
                            <TextValue text={'Amount'} value={`₦${selected.amount}`} />
                            <Divider />
                            <TextValue text={'Description'} value={selected.description} />
                            <Divider />
                            <TextValue text={'Transaction Status'} value={selected.status} />
                            <Divider />
                            <TextValue text={'Transaction Ref'} value={selected.ref} />
                            <Divider />
                            {selected.details && selected.details.balance && (
                                <>
                                    <TextValue text={'Balance Before'} value={`₦${selected.details.balance.before}`} />
                                    <Divider />
                                    <TextValue text={'Balance After'} value={`₦${selected.details.balance.after}`} />
                                    <Divider />
                                </>
                            )}
                        </Stack>
                    </Stack>
                )}
            </ModalC>
            <MainCard title="Transactions History">
                <Box sx={{ px: { xs: '0px', sm: '100px', md: '140px', lg: '180px' }, pb: '50px' }}>
                    {/* <SubCard> */}
                    <Stack spacing={'10px'}>
                        <FormControl variant="standard" sx={{ mb: '20px' }}>
                            <InputLabel htmlFor="input-with-icon-adornment">Search...</InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconSearch />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {transactions.map((transaction) => (
                            <>
                                <TransactionsCard
                                    onClick={() => {
                                        setSelected(transaction);
                                        toggleModal();
                                    }}
                                    key={transaction.id}
                                    avatarUrl={transaction.imageUrl}
                                    name={transaction.name}
                                    amount={transaction.amount}
                                />
                                <Divider />
                            </>
                        ))}
                    </Stack>
                    {/* </SubCard> */}
                </Box>
            </MainCard>
        </>
    );
};

export default Transactions;
